import { MutationTree } from 'vuex';
import { UsersState } from './types';
import {
  SET_TABLE_OPTION,
  SET_USERS,
  SET_NEW_USER_NOTIFICATION,
  SET_NEW_USER_NOTIFICATION_ERROR,
  SET_NEW_USER_NOTIFICATION_ERROR_TEXT,
  SET_DELETE_USER_NOTIFICATION,
  SET_DELETE_USER_NOTIFICATION_ERROR,
  SET_DELETE_USER_NOTIFICATION_ERROR_TEXT,
} from './mutation-types';

export const mutations: MutationTree<UsersState> = {
  [SET_USERS](state, users) {
    state.users = users;
  },
  [SET_TABLE_OPTION](state, { opt, value }) {
    Object.assign(state.tableOptions, { [opt]: value });
  },
  [SET_NEW_USER_NOTIFICATION](state, value) {
    state.newUserNotification = value;
  },
  [SET_DELETE_USER_NOTIFICATION](state, value) {
    state.deleteUserNotification = value;
  },
  [SET_DELETE_USER_NOTIFICATION_ERROR](state, value) {
    state.deleteUserNotificationError = value;
  },
  [SET_DELETE_USER_NOTIFICATION_ERROR_TEXT](state, value) {
    state.deleteUserNotificationErrorText = value;
  },
  [SET_NEW_USER_NOTIFICATION_ERROR](state, value) {
    state.newUserNotificationError = value;
  },
  [SET_NEW_USER_NOTIFICATION_ERROR_TEXT](state, value) {
    state.newUserNotificationErrorText = value;
  },
};
