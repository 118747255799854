import axios from 'axios';
import Auth from '@/helpers/auth';

export const apiURL = location.hostname === 'localhost' ? 'http://localhost:3001' : 's';

const baseURL = `${apiURL}/dashboard`;

const API = axios.create({
  baseURL,
  timeout: 10000,
});

API.interceptors.request.use(
  (config) => {
    const token = Auth.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default API;