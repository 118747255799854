import { NavigationGuard } from 'vue-router';
import Auth from '@/helpers/auth';
import { Routes } from '@/router';

export const authGuard: NavigationGuard = (to, from, next) => {
  const token = Auth.getToken();

  if (token) {
    next();
  } else {
    next(Routes.Home);
  }
};