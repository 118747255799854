
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';

import { Namespace } from '@/store/types';

import Table from '@/components/common/Table.vue';

import { UsersState } from '@/store/users/types';

@Component({
  components: {
    Table,
  },
})
export default class Users extends Vue {
  created() {
    this.fetchUsers();
  }

  headers = [{
    text: 'Name',
    value: 'formattedName',
  }, {
    text: 'gmail',
    value: 'gmail',
  }, {
    text: 'linkedin id',
    value: 'linkedinId',
  }, {
    text: 'active',
    value: 'active',
  }, {
    text: 'actions',
    value: 'actions',
  }];
  @State
  users: UsersState;

  @Action('fetchUsers', { namespace: Namespace.Users })
  fetchUsers: Function;

  @Action('changeTableOptions', { namespace: Namespace.Users })
  changeTableOptions: Function;

  @Action('addNewUser', { namespace: Namespace.Users })
  addNewUser: Function;

  @Action('deleteUser', { namespace: Namespace.Users })
  deleteUser: Function;

  @Action('searchUsers', { namespace: Namespace.Users })
  searchUsers: Function;

  @Getter('getTableOptions', { namespace: Namespace.Users })
  tableOptions: Object;
}
