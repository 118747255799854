
const TOKEN_KEY = 'grabli_token';

const TOKEN_BUFFER = 1000 * 60 * 5; // 5 minutes

// TODO: we could use "@anywhere/access-sdk" because of CloudBuild doesn't have access to EPAM artifacts
function isTokenExpired(token: TokenPayload) {
  return Date.now() >= Number(token.exp) - TOKEN_BUFFER;
}

export default {
  setToken: (token: TokenPayload) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  },

  removeToken: () => {
    localStorage.removeItem(TOKEN_KEY);
  },

  getToken: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    try {
      const decodedToken = JSON.parse(token) as TokenPayload;

      if (isTokenExpired(decodedToken)) {
        localStorage.removeItem(TOKEN_KEY);
        return null;
      }

      return decodedToken.token;
    } catch (err) {
      localStorage.removeItem(TOKEN_KEY);
      return null;
    }
  },

  getTokenFromURL: () => {
    const url = new URL(window.location.href);
    return Object.fromEntries(url.searchParams) as TokenPayload;
  },
};

type TokenPayload = {
  exp: string;
  token: string;
};
