import { GetterTree } from 'vuex';

import { UsersState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UsersState, RootState> = {
  getUsersRequestParams({ tableOptions }): Object {
    const params = {
      pageSize: tableOptions.pageSize,
      sort: tableOptions.sort,
      page: tableOptions.page,
    };
    if (tableOptions.searchQuery) {
      const queryArray: any[] = [
        { formattedName: { $regex: tableOptions.searchQuery } },
        { email: { $regex: tableOptions.searchQuery } },
        { gmail: { $regex: tableOptions.searchQuery } },
      ];
      if (!Number.isNaN(Number(tableOptions.searchQuery))) {
        queryArray.push({ $expr: {
          $regexMatch: {
            input: { $toString: '$linkedinId' },
            regex: tableOptions.searchQuery ,
          },
        } },
        );
      }
      Object.assign(params, { query: JSON.stringify({
        $or: queryArray,
      }) });
    }
    return params;
  },
  getTableOptions({ tableOptions }): Object {
    return {
      page: tableOptions.page,
      itemsPerPage: tableOptions.pageSize,
      total: tableOptions.total,
    };
  },
};
