import API from '@/api';

import { ActionTree } from 'vuex';

import { UsersState } from './types';
import { RootState } from '@/store/types';
import {
  SET_TABLE_OPTION,
  SET_USERS,
  SET_NEW_USER_NOTIFICATION,
  SET_NEW_USER_NOTIFICATION_ERROR,
  SET_NEW_USER_NOTIFICATION_ERROR_TEXT,
  SET_DELETE_USER_NOTIFICATION,
  SET_DELETE_USER_NOTIFICATION_ERROR,
  SET_DELETE_USER_NOTIFICATION_ERROR_TEXT,
} from './mutation-types';

import { epamApi } from '@/helpers/epam-api';
import { Datasets, rsql } from '@anywhere/epam-api-sdk';

export const actions: ActionTree<UsersState, RootState> = {
  async fetchUsers({ state, commit, getters }): Promise<void> {
    try {
      commit(SET_TABLE_OPTION, { opt: 'loading', value: true });
      const { getUsersRequestParams: params } = getters;
      console.log(params);
      const response = await API.get('/users', { params });
      commit(SET_USERS, response.data.rows);
      commit(SET_TABLE_OPTION, { opt: 'loading', value: false });
      commit(SET_TABLE_OPTION, { opt: 'total', value: response.data.total });
    } catch (e) {
    //
    }
  },
  changeTableOptions({ state, commit, getters, dispatch }, newOptions): void {
    if (
      newOptions.page !== state.tableOptions.page ||
      newOptions.itemsPerPage !== state.tableOptions.pageSize
    ) {
      commit(SET_TABLE_OPTION, { opt: 'page', value: newOptions.page });
      commit(SET_TABLE_OPTION, { opt: 'pageSize', value: newOptions.itemsPerPage });
      dispatch('fetchUsers');
    }
  },
  searchUsers({ commit, dispatch }, value) {
    commit(SET_TABLE_OPTION, { opt: 'searchQuery', value });
    dispatch('fetchUsers');
  },
  async addNewUser({ commit }, user) {
    try {
      const fullName = user.employeeMail.split('@')[0].split('_').join(' ');
      const { results } = await epamApi.search(Datasets.applicants, {
        q: rsql`full_name=in=(${fullName}) and employee_active==true`,
        fields: [
          'applicant_id',
          'full_name',
          'emails',
          'employee_id',
        ],
      });
      if (!results.length) {
        throw new Error('Cannot find employee with this email');
      }
      const response = await API.post('/users', { ...user, employeeId: results[0].employee_id });
      commit(SET_NEW_USER_NOTIFICATION, true);
      setTimeout(() => commit(SET_NEW_USER_NOTIFICATION, false), 5000);
    } catch (err: any) {
      commit(SET_NEW_USER_NOTIFICATION_ERROR, true);
      commit(SET_NEW_USER_NOTIFICATION_ERROR_TEXT, err.response?.data?.message || err.message);
      setTimeout(() => commit(SET_NEW_USER_NOTIFICATION_ERROR, false), 5000);
    }
  },
  async deleteUser({ commit, dispatch }, user) {
    try {
      const response = await API.delete('/user', {
        params: {
          id: user._id,
        },
      });
      console.log(response.data);
      commit(SET_DELETE_USER_NOTIFICATION, true);
      setTimeout(() => commit(SET_DELETE_USER_NOTIFICATION, false), 5000);
      dispatch('fetchUsers');
      return true;
    } catch (err: any) {
      console.log(err.response);
      console.log(err.response.data.message);
      commit(SET_DELETE_USER_NOTIFICATION_ERROR, true);
      commit(SET_DELETE_USER_NOTIFICATION_ERROR_TEXT, err.response.data.message);
      setTimeout(() => commit(SET_DELETE_USER_NOTIFICATION_ERROR, false), 5000);
      return false;
    }
  },
};
