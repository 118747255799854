/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import axios from 'axios';
import { AccessToken, EpamApiClient, EpamApiV2 } from '@anywhere/epam-api-sdk';
import auth from './auth';

const EpamApiEnv = process.env.NODE_ENV === 'production' ? 'prod' : 'staging';

function getTokenPayload(): AccessToken {
  return {
    accessToken: auth.getToken() || '',
  };
}

const createEpamApiClient = () => {
  return new EpamApiClient(axios.create(), getTokenPayload(), EpamApiEnv);
};

export const epamApi = new EpamApiV2(createEpamApiClient());
