import { Module } from 'vuex';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UsersState } from './types';
import { RootState } from '@/store/types';

export const state: UsersState = {
  users: [],
  tableOptions: {
    pageSize: 15,
    page: 1,
    total: 0,
    searchQuery: '',
    sort: '',
    loading: false,
  },
  newUserNotification: false,
  newUserNotificationError: false,
  newUserNotificationErrorText: '',
  deleteUserNotification: false,
  deleteUserNotificationError: false,
  deleteUserNotificationErrorText: '',
};

const namespaced: boolean = true;

export const users: Module<UsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
