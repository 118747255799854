
import { Component, Vue } from 'vue-property-decorator';
import { Namespace } from '@/store/types';
import { Action, State } from 'vuex-class';
import { AuthState } from '@/store/auth/types';
import router, { Routes } from '@/router';

@Component({})
export default class Home extends Vue {
  created() {
    if (this.auth.token) {
      router.push(Routes.Users);
    } else if (!this.auth.error) {
      this.login();
    }
  }

  @State
  auth: AuthState;

  @Action('login', { namespace: Namespace.Auth })
  login: Function;
};
