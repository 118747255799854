
import { Component, Vue } from 'vue-property-decorator';
import { Namespace } from '@/store/types';
import { Action } from 'vuex-class';

@Component({})
export default class OAuth extends Vue {
  created() {
    this.continueOAuth();
  }

  @Action('continueOAuth', { namespace: Namespace.Auth })
  continueOAuth: Function;
};
