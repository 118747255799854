import { apiURL } from '@/api';

import { ActionTree } from 'vuex';

import { AuthState } from './types';
import { RootState } from '@/store/types';
import { SET_SIGN_IN_ERROR, SET_TOKEN } from './mutation-types';
import router, { Routes } from '@/router';
import Auth from '@/helpers/auth';

export const actions: ActionTree<AuthState, RootState> = {
  login: async ({ commit }) => {
    commit(SET_SIGN_IN_ERROR, null);

    try {
      const href = window.location.href
      const baseURL = href.endsWith('/') ? href.slice(0, -1) : href;
      const redirectURL = `${baseURL}${Routes.OAuth}`;
      const url = encodeURI(`${apiURL}/api/access/login?chromeExtentionURI=${redirectURL}`);

      window.location.href = url;
    } catch (err) {
      commit(SET_SIGN_IN_ERROR, String(err));
    }
  },
  continueOAuth: async ({ commit }) => {
    const token = Auth.getTokenFromURL();

    if (token) {
      Auth.setToken(token);
      router.push(Routes.Users);
    } else {
      commit(SET_SIGN_IN_ERROR, 'Failed to login');
      router.push(Routes.Home);
    }
  },
  logout: ({ commit }) => {
    commit(SET_TOKEN, null);
    Auth.removeToken();
  },
};
