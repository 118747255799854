
import { validationMixin } from 'vuelidate';
import { required, email, url } from 'vuelidate/lib/validators';
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import debounce from 'lodash.debounce';

import MaterialCard from '@/components/material/Card.vue';

@Component({
  mixins: [validationMixin],
  components: {
    MaterialCard,
  },
  validations: {
    gmail: { required, email },
    linkedinUrl: { required, url },
    employeeMail: { required, email },
  },
  watch: {
    dialog(val) {
      // val || this.close();
      val;
    },
    dialogDelete(val) {
      // val || this.closeDelete();
      val;
    },
  },
})
export default class Table extends Vue {
  @Prop(Array) readonly headers: [];

  @Prop(Array) readonly items: [];

  @Prop({ type: Boolean, default: true }) loading: boolean;

  @Prop(Object) readonly options: Object;

  @Prop(Number) readonly serverItemsLength: number;

  searchValue: string = '';
  modal = false;

  gmail = '';
  linkedinUrl = '';
  employeeMail = '';
  dialogDelete = false;

  editedItem: {
    name: '',
    calories: 0,
    fat: 0,
    carbs: 0,
    protein: 0,
  };

  defaultItem: {
    name: '',
    calories: 0,
    fat: 0,
    carbs: 0,
    protein: 0,
  };
  $v: any;

  created() {
    console.log(this);
  }

  @Emit()
  pagination<T>(opt: T): T {
    return opt;
  }

  searchDebounced = debounce(this.search, 500);

  search() {
    this.$emit('search', this.searchValue);
  }

  deleteUser(item: never) {
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
    });
  }

  deleteItemConfirm() {
    this.$emit('delete-user', this.editedItem);
    this.closeDelete();
  };

  addNewUser() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    } else {
      this.$emit('add-new-user', { gmail: this.gmail, linkedinUrl: this.linkedinUrl, employeeMail: this.employeeMail });
      this.$v.$reset();
      this.modal = false;
      this.gmail = '';
      this.linkedinUrl = '';
      this.employeeMail = '';
    }
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.gmail.$dirty) {
      return errors;
    }
    !this.$v.gmail.email && errors.push('Must be valid e-mail');
    !this.$v.gmail.required && errors.push('Gmail is required');
    return errors;
  }

  get linkedinUrlErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.linkedinUrl.$dirty) {
      return errors;
    }
    !this.$v.linkedinUrl.url && errors.push('Must be valid url');
    !this.$v.linkedinUrl.required && errors.push('Linkedin url is required');
    return errors;
  }

  get employeeMailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.employeeMail.$dirty) {
      return errors;
    }
    !this.$v.employeeMail.email && errors.push('Must be valid e-mail');
    !this.$v.employeeMail.required && errors.push('Employee email is required');
    return errors;
  }
}
