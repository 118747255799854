import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Users from '@/views/Users.vue';
import OAuth from '@/views/OAuth.vue';
import { authGuard } from '@/guards/auth-guard';

Vue.use(VueRouter);

export enum Routes {
  Home = '/',
  OAuth = '/callback',
  Users = '/users',
}

const routes: RouteConfig[] = [
  {
    path: Routes.Home,
    name: 'home',
    component: Home,
  },
  { path: Routes.OAuth,
    name: 'OAuth',
    component: OAuth },
  {
    path: Routes.Users,
    name: 'users',
    component: Users,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
