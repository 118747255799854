import { MutationTree } from 'vuex';
import { AuthState } from './types';
import { SET_SIGN_IN_ERROR, SET_TOKEN } from './mutation-types';

export const mutations: MutationTree<AuthState> = {
  [SET_SIGN_IN_ERROR]: (state, error) => {
    state.error = error;
  },
  [SET_TOKEN]: (state, token) => {
    state.token = token;
  },
};
