
import Vue from 'vue';
import CoreView from '@/components/core/View.vue';

export default Vue.extend({
  name: 'App',
  components: { CoreView },
  props: {
    source: String,
    opened: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    drawer: null,
  }),
});
